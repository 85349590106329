import React from 'react';
import logo from '../../images/fafa878.png'
import './nav.css'; 

const Header = () => {

  return (
    <header className="header">
      <nav className="navbar">
        <div className="nav-center">
        <a href="https://tinyurl.com/45a9mwas" target="_blank" rel="noreferrer">
            <img
              src={logo} 
              alt="Site Logo"
              className="nav-logo"
            />
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
