import BrandShowcase from "./components/brand/brand";
import Hero from "./components/hero/hero";
import Header from "./components/nav/nav";
import LimitedTimeOffer from "./components/offer/offer";
import ReviewCarousel from "./components/review/review";

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <BrandShowcase />
      <LimitedTimeOffer />
      <ReviewCarousel />
    </div>
  );
}

export default App;
