import React from "react";
import "./hero.css";
import { FaTelegramPlane } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const Hero = () => {

  const handleLeadTracking = () => {
    if (typeof window.fbq !== 'undefined') {
      window.fbq('track', 'Lead');
      console.log('Lead event tracked');
    } else {
      console.error('Facebook Pixel not initialized');
    }
  };

  return (
    <>
      <div className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">Claim your 200% Welcome Bonus Now!</h1>
          <p className="hero-subtitle">
            Limited Time Promotion! 200% First Top Up Bonus Up to RM168!
          </p>
          <div className="button-container">
            <a
              href="https://tinyurl.com/45a9mwas"
              target="_blank"
              rel="noreferrer"
            >
              <button className="hero-button" onClick={handleLeadTracking}>Website</button>
            </a>
            <a
              href="https://t.me/fafa878_bot"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="telegram-button" onClick={handleLeadTracking}>
                <FaTelegramPlane style={{ marginRight: "8px" }} />
                Contact Us
              </button>
            </a>
          </div>
        </div>
        <div className="hero-image-placeholder">
          <img src="/zues.png" alt="Dynamic Hero" />
        </div>
         {/* Scroll Down Indicator */}
         <div className="scroll-down">
          <span>Scroll Down</span>
          <FaChevronDown className="scroll-down-icon" />
        </div>
      </div>
    </>
  );
};

export default Hero;
