import React from "react";
import "./brand.css";
import kiss from "../../images/fafa878-lp-logo-pack/918kiss.png"
import afb from "../../images/fafa878-lp-logo-pack/afb.png"
import afb2 from "../../images/fafa878-lp-logo-pack/afb2.png"
import ibc from "../../images/fafa878-lp-logo-pack/ibc.png"
import jili from "../../images/fafa878-lp-logo-pack/jili.png"
import mega from "../../images/fafa878-lp-logo-pack/mega88.png"
import pp_ca from "../../images/fafa878-lp-logo-pack/pp_ca.png"
import pplay from "../../images/fafa878-lp-logo-pack/pplay.png"
import puss from "../../images/fafa878-lp-logo-pack/pussy888.png"
import spribe from "../../images/fafa878-lp-logo-pack/spribe.png"
import tga from "../../images/fafa878-lp-logo-pack/tga.png"

const BrandShowcase = () => {
  return (
    <div className="brand-showcase">
      <div className="brand-slider">
        <img src={kiss} alt="918kiss" />
        <img src={afb} alt="afb" />
        <img src={afb2} alt="afb2" />
        <img src={ibc} alt="ibc" />
        <img src={jili} alt="jili" />
        <img src={mega} alt="mega" />
        <img src={pp_ca} alt="pragmaticplay" />
        <img src={pplay} alt="pragmatic" />
        <img src={puss} alt="pussy888" />
        <img src={spribe} alt="spribe" />
        <img src={tga} alt="tga" />

        {/* Repeated logos to fill the gap */}
        <img src={kiss} alt="918kiss" />
        <img src={afb} alt="afb" />
        <img src={afb2} alt="afb2" />
        <img src={ibc} alt="ibc" />
        <img src={jili} alt="jili" />
        <img src={mega} alt="mega" />
        <img src={pp_ca} alt="pragmaticplay" />
        <img src={pplay} alt="pragmatic" />
        <img src={puss} alt="pussy888" />
        <img src={spribe} alt="spribe" />
        <img src={tga} alt="tga" />
      </div>
    </div>
  );
};

export default BrandShowcase;
