import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";  
import "swiper/css/pagination";       
import "swiper/css/navigation";         
import "./review.css";         

import { Pagination, Navigation } from "swiper/modules";

const reviews = [
  {
    id: 1,
    name: "Date: 28/9/2024",
    feedback: "Withdraw Amount: RM 5,000",
    screenshot: "fafa878-ps/ps1.jpg",
  },
  {
    id: 2,
    name: "Date: 28/9/2024",
    feedback: "Withdraw Amount: RM 300",
    screenshot: "fafa878-ps/ps2.jpg",
  },
  {
    id: 3,
    name: "Date: 28/9/2024",
    feedback: "Withdraw Amount: RM 341",
    screenshot: "fafa878-ps/ps3.jpg",
  },
  {
    id: 4,
    name: "Date: 28/9/2024",
    feedback: "Withdraw Amount: RM 3,486",
    screenshot: "fafa878-ps/ps4.jpg",
  },
  {
    id: 5,
    name: "Date: 27/9/2024",
    feedback: "Withdraw Amount: RM 1,100",
    screenshot: "fafa878-ps/ps5.jpg",
  },
  {
    id: 6,
    name: "Date: 27/8/2024",
    feedback: "Withdraw Amount: RM 1,000",
    screenshot: "fafa878-ps/ps6.jpg",
  },
  {
    id: 7,
    name: "Date: 27/8/2024",
    feedback: "Withdraw Amount: RM 1,300",
    screenshot: "fafa878-ps/ps7.jpg",
  },
  {
    id: 8,
    name: "Date: 28/8/2024",
    feedback: "Withdraw Amount: RM 2,000",
    screenshot: "fafa878-ps/ps8.jpg",
  },
  {
    id: 9,
    name: "Date: 27/8/2024",
    feedback: "Withdraw Amount: RM 7,000",
    screenshot: "fafa878-ps/ps9.jpg",
  },
  {
    id: 10,
    name: "Date: 10/8/2024",
    feedback: "Withdraw Amount: RM 2,500",
    screenshot: "fafa878-ps/ps10.jpg",
  },
  {
    id: 11,
    name: "Date: 11/8/2024",
    feedback: "Withdraw Amount: RM 1,000",
    screenshot: "fafa878-ps/ps11.jpg",
  },
  {
    id: 12,
    name: "Date: 11/8/2024",
    feedback: "Withdraw Amount: RM 1,000",
    screenshot: "fafa878-ps/ps12.jpg",
  },
  {
    id: 13,
    name: "Date: 11/8/2024",
    feedback: "Withdraw Amount: RM 1,010",
    screenshot: "fafa878-ps/ps13.jpg",
  },
  {
    id: 14,
    name: "Date: 10/8/2024",
    feedback: "Withdraw Amount: RM 1,120",
    screenshot: "fafa878-ps/ps14.jpg",
  },
];

const ReviewCarousel = () => {
  return (
    <section className="review-carousel-section">
      <h2 className="review-carousel-title">Customer Withdraw 🔥</h2>
      <Swiper
        modules={[Pagination, Navigation]}  
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}  
        navigation={true}                 
        className="review-swiper"
      >
        {reviews.map((review) => (
          <SwiperSlide key={review.id} className="swiper-slide">
            <div className="review-card">
              <img
                src={review.screenshot}
                alt={`Review from ${review.name}`}
                className="review-screenshot"
              />
              <div className="review-info">
                <h3 className="review-name">{review.name}</h3>
                <p className="review-rating">{review.rating}</p>
                <p className="review-feedback">{review.feedback}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ReviewCarousel;
